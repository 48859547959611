import { IDesktopMeetingWindowState } from '@rmvw/c-common';
import * as React from 'react';

import Logger from '../../lib/observability/Logger';
import { useAppMeetingState } from '../../providers/AppMeetingStateProvider';

/**
 * Manages the Desktop app meeting window state
 */
export default function useDesktopMeetingWindowManager() {
  const { hideMeeting, meetingState } = useAppMeetingState();

  /**
   * Subscribe to meeting window state change events
   */
  React.useEffect(() => {
    if (!window._desktopBridge) {
      return;
    }

    const _listener = ({ joined, threadId }: IDesktopMeetingWindowState) => {
      if (!joined && threadId === meetingState.threadId) {
        hideMeeting(); // auto-hide meeting window if meeting has ended
      }
    };

    const _unsubscribe = window._desktopBridge.receiveMeetingWindowState?.(_listener);
    return () => _unsubscribe?.();
  }, [hideMeeting, meetingState]);

  /**
   * Manage window state
   */
  React.useEffect(() => {
    if (!window._desktopBridge) {
      return;
    }

    if (meetingState.threadId) {
      window._desktopBridge
        .showMeetingWindow?.({ threadId: meetingState.threadId, options: meetingState.options })
        .catch((e) => Logger.error(e as Error, '[useDesktopMeetingWindowManager] Failed to show meeting window'));
    } else {
      window._desktopBridge
        .hideMeetingWindow?.()
        .catch((e) => Logger.error(e as Error, '[useDesktopMeetingWindowManager] Failed to hide meeting window'));
    }
  }, [meetingState]);
}
