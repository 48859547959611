import * as React from 'react';
import styled from 'styled-components';

import Spinner from '../Spinner';

const _Spinner = styled(Spinner)`
  background-color: ${({ theme }) => theme.color.background800};
`;

function SuspenseSpinner({ children }: React.PropsWithChildren<unknown>) {
  return <React.Suspense fallback={<_Spinner fullScreen />}>{children}</React.Suspense>;
}

export default SuspenseSpinner;
