import { gql } from '@apollo/client';
import * as React from 'react';

import Logger from '../lib/observability/Logger';

import { useInboxUnreadCount } from './useInboxUnreadCount';

const InboxUnreadCount = gql`
  query useNativeAppBadge_InboxUnreadCount {
    inboxUnreadCount
  }
`;

/**
 * Updates the native app badge with the inbox unread count.
 */
export default function useNativeAppBadge() {
  const unreadCount = useInboxUnreadCount(!window._desktopBridge && !window._mobileBridge);

  React.useEffect(() => {
    if (window._desktopBridge) {
      window._desktopBridge
        .setDockBadge?.({ badge: unreadCount > 0 ? unreadCount.toString() : '' })
        .catch((e) => Logger.error(e as Error, '[useNativeAppBadge] Failed to set dock badge'));
      if (unreadCount) {
        window._desktopBridge
          .bounceDock?.()
          .catch((e) => Logger.error(e as Error, '[useNativeAppBadge] Failed to bounce dock'));
      }
    } else if (window._mobileBridge) {
      window._mobileBridge.setBadge?.(unreadCount);
    }
  }, [unreadCount]);
}
