import * as React from 'react';

import Logger from '../lib/observability/Logger';

/**
 * Clears electron history when we first bootstrap the app
 */
export default function useDesktopClearHistory() {
  React.useEffect(() => {
    if (!window._desktopBridge) {
      return;
    }
    window._desktopBridge
      ?.clearHistory?.()
      .catch((e) => Logger.error(e as Error, '[useDesktopClearHistory] Failed to clear history'));
  }, []);
}
