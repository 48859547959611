import { IS_DEV, IS_STAGING } from '@rmvw/x-common';
import * as React from 'react';
import { render } from 'react-dom';
import { Helmet } from 'react-helmet';
import { BrowserRouter } from 'react-router-dom';

import { AppMainHeaderProvider } from '../components/Header/MainWindowHeader/AppMainHeaderProvider';
import Logger from '../lib/observability/Logger';
import SentryAgent from '../lib/observability/SentryAgent';
import MainApp from '../MainApp';
import AppMeetingStateProvider from '../providers/AppMeetingStateProvider';

import CommonEntrypointProviders from './helpers/CommonEntrypointProviders';

const faviconPng = !IS_STAGING
  ? require('../../static/images/app-icons/prod/favicon-32x32.png')
  : !IS_DEV
  ? require('../../static/images/app-icons/staging/favicon-32x32.png')
  : require('../../static/images/app-icons/sb/favicon-32x32.png');

const touchIconPng = !IS_STAGING
  ? require('../../static/images/app-icons/prod/1024x1024.png')
  : !IS_DEV
  ? require('../../static/images/app-icons/staging/1024x1024.png')
  : require('../../static/images/app-icons/sb/1024x1024.png');

SentryAgent.init(); // Init sentry error logging

async function bootstrap() {
  render(
    <CommonEntrypointProviders>
      <BrowserRouter>
        <AppMainHeaderProvider>
          <AppMeetingStateProvider>
            <Helmet>
              <link rel="icon" href={faviconPng} type="image/png" />

              {/* Apple specific */}
              <link rel="apple-touch-icon" href={touchIconPng} />
              <meta name="apple-mobile-web-app-title" content="emdash" />
            </Helmet>
            <MainApp />
          </AppMeetingStateProvider>
        </AppMainHeaderProvider>
      </BrowserRouter>
    </CommonEntrypointProviders>,
    document.getElementById('root')
  );
}

bootstrap().catch((e) => Logger.error(e as Error, '[entrypoints/main] Failed to bootstrap'));
