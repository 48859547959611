import useLocalStorage from '@rehooks/local-storage';
import { IS_DEV, SUPPRESS_DEEPLINK_INTERSTITIAL_QUERY_PARAM_KEY, getIdFromPath } from '@rmvw/x-common';
import * as React from 'react';
import { UAParser } from 'ua-parser-js';

import { IS_DESKTOP_APP, IS_MOBILE_APP } from '../env';

import useQueryParams from './useQueryParams';

const HIDE_DEEPLINK_INTERSTITIAL_STORAGE_KEY = 'di_lastHidden';
const HIDE_DEEPLINK_INTERSTITIAL_TIMEOUT_H = 72; // 3 days (arbitrary)

const uaParser = new UAParser();

export default function useDesktopDeeplinkManager() {
  const queryParams = useQueryParams();

  const [lastHiddenTimestamp, setLastHiddenTimestamp] = useLocalStorage<number>(HIDE_DEEPLINK_INTERSTITIAL_STORAGE_KEY);
  const recentlyHidden =
    lastHiddenTimestamp && Date.now() - lastHiddenTimestamp < HIDE_DEEPLINK_INTERSTITIAL_TIMEOUT_H * 60 * 60 * 1000;

  const isMacOS = uaParser.getOS().name === 'Mac OS'; // We only support macOS for now for desktop app
  const isSafari = uaParser.getBrowser().name === 'Safari'; // Safari doesn't tell us if it's iOS or macOS, so we just assume it's iOS

  const eligibleBrowser = !IS_DESKTOP_APP && !IS_MOBILE_APP && isMacOS && !isSafari;
  const eligiblePath = !!getIdFromPath(window.location.pathname.replace(/\/meet$/, '')); // only show interstitial if we open app on deeplink path

  // Suppress interstitial if explicitly requested via query param or if we're in dev mode
  const interstitialSuppressed = IS_DEV || !!queryParams.get(SUPPRESS_DEEPLINK_INTERSTITIAL_QUERY_PARAM_KEY);

  const [interstitialVisible, setInterstitialVisible] = React.useState(
    !recentlyHidden && !interstitialSuppressed && eligibleBrowser && eligiblePath
  );

  return React.useMemo(
    () => ({
      interstitialVisible,
      hideInterstitial: () => {
        setInterstitialVisible(false);
        // Make sure we don't show the interstitial again for a while if explicitly dismissed (to handle refresh case)
        setLastHiddenTimestamp(Date.now());
      },
    }),
    [interstitialVisible, setLastHiddenTimestamp]
  );
}
