import * as React from 'react';

import { useAppMeetingState } from '../../providers/AppMeetingStateProvider';

/**
 * Manages the Mobile app meeting modal state
 */
export default function useMobileMeetingModalManager() {
  const { hideMeeting, meetingState } = useAppMeetingState();

  /**
   * Manage modal state
   */
  React.useEffect(() => {
    if (!window._mobileBridge) {
      return;
    }

    if (meetingState.threadId) {
      window._mobileBridge.showMeetingModal?.({ threadId: meetingState.threadId, options: meetingState.options });

      // The label on this method is a bit misleading here...we're not actually hiding the meeting,
      // we're just preemptively resetting the state of AppMeetingStateProvider so that we can launch
      // a new meeting again after the full screen modal is closed.
      hideMeeting();
    }
  }, [hideMeeting, meetingState]);
}
