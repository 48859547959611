import { Paths } from '@rmvw/x-common';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * Registers a handler to process remote route push events
 */
export default function useDesktopRemoteRoutePushHandler() {
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!window._desktopBridge) {
      return; // this code applicable only in desktop app context
    }
    const listener = ({ path }: { path: string }) => {
      if (path.startsWith(Paths.LOGIN)) {
        // Special case: a main window route change request to login page should
        // always trigger a full page reload so that we can a/ clear the current
        // user session and b/ ensure the login triggers the correct auth flow
        window.location.assign(path);
      } else {
        navigate(path);
      }
    };
    const removeListener = window._desktopBridge.receivePushMainWindowRoute?.(listener);
    return () => removeListener?.();
  }, [navigate]);
}
