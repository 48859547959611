import { Paths } from '@rmvw/x-common';
import * as React from 'react';
import { HotkeysProvider } from 'react-hotkeys-hook';
import { Navigate, Route, Routes } from 'react-router-dom';

import SuspenseSpinner from './components/SuspenseSpinner';
import useDesktopMeetingWindowManager from './hooks/meeting-window/useDesktopMeetingWindowManager';
import useMobileMeetingModalManager from './hooks/meeting-window/useMobileMeetingModalManager';
import useWebMeetingWindowManager from './hooks/meeting-window/useWebMeetingWindowManager';
import useDesktopClearHistory from './hooks/useDesktopClearHistory';
import useDesktopDeeplinkManager from './hooks/useDesktopDeeplinkManager';
import useDesktopRemoteRoutePushHandler from './hooks/useDesktopRemoteRoutePushHandler';
import useNativeAppBadge from './hooks/useNativeAppBadge';
import AssistantProvider from './providers/AssistantProvider/AssistantProvider';
import NavigationLayoutProvider from './providers/NavigationLayoutProvider/NavigationLayoutProvider';

const DesktopDeeplinkInterstitial = React.lazy(
  () => import('./scenes/desktop-deeplink-interstitial/DesktopDeeplinkInterstitial')
);
const DesktopLoginCTAScene = React.lazy(() => import('./scenes/login/DesktopLoginCTAScene'));
const DownloadScene = React.lazy(() => import('./scenes/download/DownloadScene'));
const GoogleAPIDisclosure = React.lazy(() => import('./components/Legal/GoogleAPIDisclosure'));
const LoggedInContainer = React.lazy(() => import('./LoggedInContainer'));
const LoginCodeScene = React.lazy(() => import('./scenes/login/LoginCodeChallengeScene'));
const LoginForDesktopScene = React.lazy(() => import('./scenes/login/LoginForDesktopScene'));
const Privacy = React.lazy(() => import('./components/Legal/Privacy'));
const Terms = React.lazy(() => import('./components/Legal/Terms'));
const WebLoginScene = React.lazy(() => import('./scenes/login/WebLoginScene/WebLoginScene'));

export default function MainApp() {
  // Management hooks
  useDesktopClearHistory();
  useDesktopMeetingWindowManager();
  useDesktopRemoteRoutePushHandler();
  useMobileMeetingModalManager();
  useNativeAppBadge();
  useWebMeetingWindowManager();

  const { hideInterstitial, interstitialVisible } = useDesktopDeeplinkManager();
  if (interstitialVisible) {
    return (
      <SuspenseSpinner>
        <DesktopDeeplinkInterstitial onHideInterstitial={hideInterstitial} />
      </SuspenseSpinner>
    );
  }

  return (
    <Routes>
      <Route path={Paths.LOGIN}>
        <Route
          path={Paths.LOGIN_CODE_CHALLENGE}
          element={
            <SuspenseSpinner>
              <LoginCodeScene />
            </SuspenseSpinner>
          }
        />
        <Route
          path={Paths.DESKTOP_LOGIN_CTA}
          element={
            <SuspenseSpinner>
              <DesktopLoginCTAScene />
            </SuspenseSpinner>
          }
        />
        <Route
          path={Paths.LOGIN}
          element={
            <SuspenseSpinner>
              <WebLoginScene />
            </SuspenseSpinner>
          }
        />
        <Route
          path={Paths.LOGIN_FOR_DESKTOP}
          element={
            <SuspenseSpinner>
              <LoginForDesktopScene />
            </SuspenseSpinner>
          }
        />
        <Route index path="*" element={<Navigate to={Paths.LOGIN} />} />
      </Route>

      <Route path="legal">
        <Route
          path={Paths.LEGAL__PRIVACY}
          element={
            <SuspenseSpinner>
              <Privacy />
            </SuspenseSpinner>
          }
        />
        <Route
          path={Paths.LEGAL__TERMS}
          element={
            <SuspenseSpinner>
              <Terms />
            </SuspenseSpinner>
          }
        />
        <Route
          path={Paths.LEGAL__GOOGLE_API_DISCLOSURE}
          element={
            <SuspenseSpinner>
              <GoogleAPIDisclosure />
            </SuspenseSpinner>
          }
        />
        <Route index path="*" element={<Navigate to="privacy" />} />
      </Route>

      <Route>
        <Route
          path={Paths.DOWNLOAD}
          element={
            <SuspenseSpinner>
              <DownloadScene />
            </SuspenseSpinner>
          }
        />
      </Route>

      <Route
        path="*"
        element={
          <SuspenseSpinner>
            <HotkeysProvider>
              <NavigationLayoutProvider storageKeyPrefix="mainApp">
                <AssistantProvider>
                  <LoggedInContainer />
                </AssistantProvider>
              </NavigationLayoutProvider>
            </HotkeysProvider>
          </SuspenseSpinner>
        }
      />
    </Routes>
  );
}
